<template>
  <section class="pb-2">
    <b-card>
      <b-breadcrumb>
        <div class="d-flex align-items-center">
          <span class="text-primary">Report </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Report Auto Vendute</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-breadcrumb>
    </b-card>

    <b-row class="mb-2">
      <b-col md="6">
        <b-card class="h-100">
          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" size="50">
              <span class="d-flex align-items-center">
                <feather-icon icon="TrendingUpIcon" size="20" />
              </span>
            </b-avatar>
            <span class="ml-1 text-primary h2"> 0000,00 € </span>
          </div>

          <div class="mt-2">
            <h2>Fatturato del mese</h2>
            <span>Il fatturato del mese corrente</span>
          </div>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card class="h-100 justify-content-center">
          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" size="50">
              <span class="d-flex align-items-center">
                <feather-icon icon="TrendingUpIcon" size="20" />
              </span>
            </b-avatar>
            <span class="ml-1 text-primary h2"> 0000,00 € </span>
          </div>

          <div class="mt-2">
            <h2>Fatturato da inizio dell'anno</h2>
            <span>Il fatturato dall'inizio dell'anno corrente</span>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col md="6">
        <b-card class="h-100">
          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" size="50">
              <span class="d-flex align-items-center">
                <feather-icon icon="TrendingUpIcon" size="20" />
              </span>
            </b-avatar>
            <span class="ml-1 text-success h2">+ 0000,00 € </span>
          </div>

          <div class="mt-2">
            <h2>Incremento del fatturato rispetto al mese precedente</h2>
          </div>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card class="h-100">
          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" size="50">
              <span class="d-flex align-items-center">
                <feather-icon icon="TrendingDownIcon" size="20" />
              </span>
            </b-avatar>
            <span class="ml-1 text-danger h2"> - 0000,00 € </span>
          </div>

          <div class="mt-2">
            <h2>
              Incremento del fatturato relativo allo stesso mese dell'anno
              precedente
            </h2>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card>
          <h2>Vendita auto per mese corrente</h2>

          <chartjs-component-bar-chart
            :height="100"
            :data="chartJSData.data"
            :options="chartJSData.options"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="8">
        <b-card>
          <h2>Funnel del mese corrente</h2>
        </b-card>
      </b-col>

      <b-col md="4">
        <b-row>
          <b-col class="mb-2" md="12">
            <b-card class="h-100">
              <div class="d-flex align-items-center">
                <b-avatar variant="light-primary" size="50">
                  <span class="d-flex align-items-center">
                    <feather-icon icon="TrendingUpIcon" size="20" />
                  </span>
                </b-avatar>
                <span class="ml-1 text-primary h2"> 0000,00 € </span>
              </div>

              <div class="mt-2">
                <h2>Vendute nel mese</h2>
                <span> Quante auto sono state vendute nel mese corrente </span>
              </div>
            </b-card>
          </b-col>

          <b-col md="12">
            <b-card class="h-100">
              <div class="d-flex align-items-center">
                <b-avatar variant="light-primary" size="50">
                  <span class="d-flex align-items-center">
                    <feather-icon icon="TrendingUpIcon" size="20" />
                  </span>
                </b-avatar>
                <span class="ml-1 text-primary h2"> 0000,00 € </span>
              </div>

              <div class="mt-2">
                <h2>Vendute da inizio anno</h2>
                <span>
                  Quante auto sono state vendute dall'inizio dell'anno
                </span>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAvatar,
  BButton,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
} from "bootstrap-vue";

import ChartjsComponentBarChart from "@/components/charts/ChartjsComponentBarChart.vue";
import { $themeColors } from '@themeConfig';

export default {
  components: {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    ChartjsComponentBarChart,
  },

  data: () => ({
    chartJSData: {
      data: {
        labels: ["Gennario", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
        datasets: [{ data: [40, 20, 12, 13, 90, 32, 23, 54, 12, 10, 12, 9] }],
      },
      options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },

        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,

        legend: {
          display: false,
        },

        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          color: $themeColors.primary,
  
        },

        scales: {
        xAxes: [
          {
            display: true,
           
            scaleLabel: {
              display: false,
            },
         
          },
        ],
        yAxes: [
          {
            display: true,
          
            ticks: {
              stepSize: 100,
              min: 0,
              max: 400,
             
            },
          },
        ],
      },
    
      },
      },
    },
  }),
};
</script>
